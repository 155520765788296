var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(!_vm.curricular_mesh)?_c('h4',{staticClass:"mt-2"},[_vm._v(" "+_vm._s(_vm.$getVisibleNames( "mesh.egressprofilecompetencemention", true, "Menciones" ))+" ")]):_vm._e(),_c('b-table-simple',{attrs:{"responsive":""}},[_c('b-thead',[_c('b-tr',[(_vm.allows_crud && _vm.draggableList.length > 0)?_c('b-th',{staticClass:"text-center",staticStyle:{"width":"3%"}}):_vm._e(),_c('b-th',{staticClass:"text-center",style:(`width: ${_vm.curricular_mesh ? 45 : 22}%`)},[_vm._v(" "+_vm._s(_vm.$getVisibleNames( "mesh.egressprofilecompetencemention", false, "Mención" ))+" ")]),(!_vm.curricular_mesh)?_c('b-th',{staticClass:"text-center",staticStyle:{"width":"25%"}},[_vm._v(" Descripción ")]):_vm._e(),_c('b-th',{staticClass:"text-center",staticStyle:{"width":"47%"}},[_vm._v(" "+_vm._s(_vm.$getVisibleNames("mesh.competence", true, "Competencias"))+" "),_c('div',{staticClass:"profile-competence-button-div"},[(_vm.allows_crud)?_c('b-button',{staticClass:"mx-1",attrs:{"size":"sm"},on:{"click":function($event){return _vm.$bvModal.show(
                  `add-profile-competence-mention-modal-${_vm.egress_profile_id}`
                )}}},[_vm._v("Crear "+_vm._s(_vm.$getVisibleNames( "mesh.egressprofilecompetencemention", false, "Mención" )))]):_vm._e()],1)]),(_vm.allows_crud && _vm.draggableList.length > 0)?_c('b-th',{staticStyle:{"width":"2%"}}):_vm._e()],1)],1),_c('draggable',{attrs:{"disabled":!_vm.allows_crud,"tag":"b-tbody","handle":".handle"},model:{value:(_vm.draggableList),callback:function ($$v) {_vm.draggableList=$$v},expression:"draggableList"}},[(_vm.draggableList.length > 0)?_vm._l((_vm.draggableList),function(mention){return _c('b-tr',{key:mention.id},[(_vm.allows_crud)?_c('b-td',{staticClass:"text-center handle",class:{ grabbable: _vm.allows_crud },style:(`${
              !_vm.curricular_mesh
                ? 'border: 1px solid black; height: 4rem !important;'
                : ''
            }`)},[(_vm.allows_crud)?_c('b-icon',{attrs:{"icon":"arrows-expand","scale":"1.2"}}):_vm._e()],1):_vm._e(),_c('b-td',{staticClass:"text-center",style:(`${
              !_vm.curricular_mesh
                ? 'border: 1px solid black; height: 4rem !important;'
                : ''
            }`)},[_vm._v(" "+_vm._s(mention.name))]),(!_vm.curricular_mesh)?_c('b-td',{staticClass:"px-2",style:(`${
              !_vm.curricular_mesh
                ? 'border: 1px solid black; height: 4rem !important;'
                : ''
            }`)},[_c('div',{staticClass:"rich-text-content",domProps:{"innerHTML":_vm._s(mention.description)}})]):_vm._e(),_c('b-td',{class:mention.egress_profile_competences.length > 0
                ? 'text-justify'
                : 'text-center',style:(`${
              !_vm.curricular_mesh
                ? 'border: 1px solid black; height: 4rem !important;'
                : ''
            }`)},[(mention.egress_profile_competences.length > 0)?[_c('div',{class:!_vm.curricular_mesh ? 'badge-containerEPC ' : 'badge-container'},_vm._l((_vm.ProfileCompetenceMentionBadge(
                    mention.egress_profile_competences
                  )),function(profile_competence){return _c('div',{key:profile_competence.id + '-' + mention.id,class:!_vm.curricular_mesh ? 'badgeEPC' : 'badge',attrs:{"id":profile_competence.id + '-' + mention.id}},[_vm._v(" "+_vm._s(profile_competence.order)+_vm._s(profile_competence.profile_cycle_order != 1000 ? "." + profile_competence.profile_cycle_order : "")+" "),_c('b-popover',{attrs:{"target":profile_competence.id + '-' + mention.id,"placement":"left","triggers":"hover focus"}},[_c('div',{staticClass:"competence-div d-flex"},[_c('span',[_vm._v(" "+_vm._s(profile_competence.order)+_vm._s(profile_competence.profile_cycle_order != 1000 ? "." + profile_competence.profile_cycle_order : "")+" ")]),_c('SentenceContainer',{attrs:{"Sentence":profile_competence}})],1)])],1)}),0)]:[_vm._v(" N/A ")]],2),(_vm.allows_crud)?_c('b-td',{staticClass:"text-center",style:(`${
              !_vm.curricular_mesh
                ? 'border: 1px solid black; height: 4rem !important;'
                : ''
            }`)},[(_vm.allows_crud)?_c('button-edit',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.v-secondary.noninteractive.top",value:(
                `Editar ${_vm.$getVisibleNames(
                  'mesh.egressprofilecompetencemention',
                  false,
                  'Mención'
                )}`
              ),expression:"\n                `Editar ${$getVisibleNames(\n                  'mesh.egressprofilecompetencemention',\n                  false,\n                  'Mención'\n                )}`\n              ",modifiers:{"v-secondary":true,"noninteractive":true,"top":true}}],attrs:{"fill":"white"},on:{"click":function($event){return _vm.$bvModal.show(
                  `edit-profile-competence-mention-modal-${mention.id}`
                )}}}):_vm._e(),_c('button-delete',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.v-secondary.noninteractive.top",value:(
                `Eliminar ${_vm.$getVisibleNames(
                  'mesh.egressprofilecompetencemention',
                  false,
                  'Mención'
                )}`
              ),expression:"\n                `Eliminar ${$getVisibleNames(\n                  'mesh.egressprofilecompetencemention',\n                  false,\n                  'Mención'\n                )}`\n              ",modifiers:{"v-secondary":true,"noninteractive":true,"top":true}}],on:{"click":function($event){return _vm.askForDeleteObject(mention)}}}),_c('b-modal',{attrs:{"id":`edit-profile-competence-mention-modal-${mention.id}`,"hide-footer":true,"title":`Editar ${_vm.$getVisibleNames(
                'mesh.egressprofilecompetencemention',
                false,
                'Mención'
              )}`,"size":"xl"}},[_c('EgressProfileCompetenceMentionForm',{attrs:{"ProfileCompetenceMention":mention,"show_delete_button":true,"egress_profile_id":_vm.egress_profile_id},on:{"updated":function($event){return _vm.$bvModal.hide(
                    `edit-profile-competence-mention-modal-${mention.id}`
                  )}}})],1)],1):_vm._e()],1)}):_c('b-tr',[_c('b-td',{staticClass:"text-center",style:(`${
            !_vm.curricular_mesh
              ? 'border: 1px solid black; height: 4rem !important;'
              : ''
          }`),attrs:{"colspan":"8"}},[_vm._v("No hay "+_vm._s(_vm.$getVisibleNames( "mesh.egressprofilecompetencemention", true, "Menciones" ))+" para mostrar.")])],1)],2)],1),_c('b-modal',{attrs:{"id":`add-profile-competence-mention-modal-${_vm.egress_profile_id}`,"hide-footer":true,"title":`Crear ${_vm.$getVisibleNames(
      'mesh.egressprofilecompetencemention',
      false,
      'Mención'
    )}`,"size":"xl"}},[_c('EgressProfileCompetenceMentionForm',{attrs:{"egress_profile_id":_vm.egress_profile_id},on:{"created":function($event){return _vm.$bvModal.hide(
          `add-profile-competence-mention-modal-${_vm.egress_profile_id}`
        )}}})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }