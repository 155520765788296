<template>
  <div>
    <h4 v-if="!curricular_mesh" class="mt-2">
      {{
        $getVisibleNames(
          "mesh.egressprofilecompetencemention",
          true,
          "Menciones"
        )
      }}
    </h4>
    <b-table-simple responsive>
      <b-thead>
        <b-tr>
          <b-th
            style="width: 3%"
            class="text-center"
            v-if="allows_crud && draggableList.length > 0"
          >
          </b-th>
          <b-th
            :style="`width: ${curricular_mesh ? 45 : 22}%`"
            class="text-center"
          >
            {{
              $getVisibleNames(
                "mesh.egressprofilecompetencemention",
                false,
                "Mención"
              )
            }}
          </b-th>
          <b-th v-if="!curricular_mesh" style="width: 25%" class="text-center">
            Descripción
          </b-th>
          <b-th style="width: 47%" class="text-center">
            {{ $getVisibleNames("mesh.competence", true, "Competencias") }}
            <div class="profile-competence-button-div">
              <b-button
                v-if="allows_crud"
                class="mx-1"
                size="sm"
                @click="
                  $bvModal.show(
                    `add-profile-competence-mention-modal-${egress_profile_id}`
                  )
                "
                >Crear
                {{
                  $getVisibleNames(
                    "mesh.egressprofilecompetencemention",
                    false,
                    "Mención"
                  )
                }}</b-button
              >
            </div>
          </b-th>
          <b-th
            style="width: 2%"
            v-if="allows_crud && draggableList.length > 0"
          ></b-th>
        </b-tr>
      </b-thead>
      <draggable
        v-model="draggableList"
        :disabled="!allows_crud"
        tag="b-tbody"
        handle=".handle"
      >
        <template v-if="draggableList.length > 0">
          <b-tr v-for="mention in draggableList" :key="mention.id">
            <b-td
              :style="`${
                !curricular_mesh
                  ? 'border: 1px solid black; height: 4rem !important;'
                  : ''
              }`"
              class="text-center handle"
              :class="{ grabbable: allows_crud }"
              v-if="allows_crud"
              ><b-icon
                v-if="allows_crud"
                icon="arrows-expand"
                scale="1.2"
              ></b-icon
            ></b-td>
            <b-td
              :style="`${
                !curricular_mesh
                  ? 'border: 1px solid black; height: 4rem !important;'
                  : ''
              }`"
              class="text-center"
            >
              {{ mention.name }}</b-td
            >
            <b-td
              :style="`${
                !curricular_mesh
                  ? 'border: 1px solid black; height: 4rem !important;'
                  : ''
              }`"
              v-if="!curricular_mesh"
              class="px-2"
            >
              <div v-html="mention.description" class="rich-text-content"></div>
            </b-td>
            <b-td
              :style="`${
                !curricular_mesh
                  ? 'border: 1px solid black; height: 4rem !important;'
                  : ''
              }`"
              :class="
                mention.egress_profile_competences.length > 0
                  ? 'text-justify'
                  : 'text-center'
              "
            >
              <template v-if="mention.egress_profile_competences.length > 0">
                <div
                  :class="
                    !curricular_mesh ? 'badge-containerEPC ' : 'badge-container'
                  "
                >
                  <div
                    v-for="profile_competence in ProfileCompetenceMentionBadge(
                      mention.egress_profile_competences
                    )"
                    :key="profile_competence.id + '-' + mention.id"
                    :id="profile_competence.id + '-' + mention.id"
                    :class="!curricular_mesh ? 'badgeEPC' : 'badge'"
                  >
                    {{ profile_competence.order
                    }}{{
                      profile_competence.profile_cycle_order != 1000
                        ? "." + profile_competence.profile_cycle_order
                        : ""
                    }}
                    <b-popover
                      :target="profile_competence.id + '-' + mention.id"
                      placement="left"
                      triggers="hover focus"
                    >
                      <div class="competence-div d-flex">
                        <span>
                          {{ profile_competence.order
                          }}{{
                            profile_competence.profile_cycle_order != 1000
                              ? "." + profile_competence.profile_cycle_order
                              : ""
                          }}
                        </span>
                        <SentenceContainer
                          :Sentence="profile_competence"
                        ></SentenceContainer>
                      </div>
                    </b-popover>
                  </div>
                </div>
              </template>
              <template v-else> N/A </template>
            </b-td>
            <b-td
              :style="`${
                !curricular_mesh
                  ? 'border: 1px solid black; height: 4rem !important;'
                  : ''
              }`"
              class="text-center"
              v-if="allows_crud"
            >
              <button-edit
                v-if="allows_crud"
                fill="white"
                v-b-tooltip.v-secondary.noninteractive.top="
                  `Editar ${$getVisibleNames(
                    'mesh.egressprofilecompetencemention',
                    false,
                    'Mención'
                  )}`
                "
                @click="
                  $bvModal.show(
                    `edit-profile-competence-mention-modal-${mention.id}`
                  )
                "
              ></button-edit>
              <button-delete
                v-b-tooltip.v-secondary.noninteractive.top="
                  `Eliminar ${$getVisibleNames(
                    'mesh.egressprofilecompetencemention',
                    false,
                    'Mención'
                  )}`
                "
                @click="askForDeleteObject(mention)"
              ></button-delete>
              <b-modal
                :id="`edit-profile-competence-mention-modal-${mention.id}`"
                :hide-footer="true"
                :title="`Editar ${$getVisibleNames(
                  'mesh.egressprofilecompetencemention',
                  false,
                  'Mención'
                )}`"
                size="xl"
              >
                <EgressProfileCompetenceMentionForm
                  :ProfileCompetenceMention="mention"
                  :show_delete_button="true"
                  :egress_profile_id="egress_profile_id"
                  @updated="
                    $bvModal.hide(
                      `edit-profile-competence-mention-modal-${mention.id}`
                    )
                  "
                ></EgressProfileCompetenceMentionForm>
              </b-modal>
            </b-td>
          </b-tr>
        </template>
        <b-tr v-else>
          <b-td
            :style="`${
              !curricular_mesh
                ? 'border: 1px solid black; height: 4rem !important;'
                : ''
            }`"
            class="text-center"
            colspan="8"
            >No hay
            {{
              $getVisibleNames(
                "mesh.egressprofilecompetencemention",
                true,
                "Menciones"
              )
            }}
            para mostrar.</b-td
          >
        </b-tr>
      </draggable>
    </b-table-simple>
    <b-modal
      :id="`add-profile-competence-mention-modal-${egress_profile_id}`"
      :hide-footer="true"
      :title="`Crear ${$getVisibleNames(
        'mesh.egressprofilecompetencemention',
        false,
        'Mención'
      )}`"
      size="xl"
    >
      <EgressProfileCompetenceMentionForm
        :egress_profile_id="egress_profile_id"
        @created="
          $bvModal.hide(
            `add-profile-competence-mention-modal-${egress_profile_id}`
          )
        "
      ></EgressProfileCompetenceMentionForm>
    </b-modal>
  </div>
</template>

<script>
import draggable from "vuedraggable";
import * as names from "@/store/names";
import { mapGetters } from "vuex";
import { toast } from "@/utils/utils";
export default {
  name: "EgressProfileCompetenceMention",
  components: {
    draggable,
    EgressProfileCompetenceMentionForm: () =>
      import("./EgressProfileCompetenceMentionForm"),
    SentenceContainer: () => import("@/components/reusable/SentenceContainer"),
  },
  props: {
    type: Number,
    egress_profile_id: {
      required: true,
    },
    allows_crud: {
      type: Boolean,
    },
    curricular_mesh: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {};
  },
  computed: {
    ...mapGetters({
      competences: names.COMPETENCES,
      egressProfileCycles: names.PROFILE_CYCLES,
      profile_competences: names.PROFILE_COMPETENCES,
      profile_competences_mention: names.PROFILE_COMPETENCES_MENTION,
    }),
    draggableList: {
      get() {
        let list = this.profile_competences_mention.filter(
          (x) => x.egress_profile == this.egress_profile_id
        );
        list.sort(function (a, b) {
          if (a.order < b.order) return -1;
          if (a.order > b.order) return 1;
          return 0;
        });
        let order = 1;
        list.forEach((element) => {
          if (element.order != order) element.order = order;
          order += 1;
        });
        return list;
      },
      set(list) {
        let order = 1;
        list.forEach((element) => {
          if (element.order != order) {
            element.order = order;
            if (!isNaN(element.id)) {
              this.patchMention({
                profile_competence_mention_id: element.id,
                item: { order: element.order },
              });
            }
          }
          order += 1;
        });
      },
    },
  },
  methods: {
    ProfileCompetenceMentionBadge(profile_competences_mention) {
      let list = [];
      list = this.profile_competences
        .filter(
          (x) =>
            x.egress_profile == this.egress_profile_id &&
            profile_competences_mention.includes(x.id)
        )
        .map((objeto) => {
          const competence = this.competences.find(
            (x) => x.id == objeto.competence
          ) || {
            action: "",
            content: "",
            condition: "",
            verb: null,
            full_sentence: "",
          };
          return {
            ...objeto,
            action: competence.action,
            content: competence.content,
            condition: competence.condition,
            verb: competence.verb,
            full_sentence: competence.full_sentence,
            profile_cycle_order: (
              this.egressProfileCycles.find(
                (x) =>
                  x.egress_profile == this.egress_profile_id &&
                  x.cycle == objeto.cycle
              ) || { order: 1000 }
            ).order,
          };
        });
      return list.sort(function (a, b) {
        if (a.order > b.order) return 1;
        if (a.order < b.order) return -1;
        if (a.profile_cycle_order > b.profile_cycle_order) return 1;
        if (a.profile_cycle_order < b.profile_cycle_order) return -1;
        return 0;
      });
    },
    patchMention(payload) {
      this.$store.dispatch(names.PATCH_PROFILE_COMPETENCE_MENTION, payload);
    },
    askForDeleteObject(profile_competence_mention) {
      this.$swal({
        title: `¿Está seguro de que desea eliminar esta instancia de "${this.$getVisibleNames(
          "mesh.egressprofilecompetencemention",
          false,
          "Mención"
        )}"?`,
        text: "¡Esta acción no se podrá revertir!",
        type: "warning",
        showCancelButton: true,
      }).then((result) => {
        if (result.value) {
          this.$store.dispatch(
            names.DELETE_PROFILE_COMPETENCE_MENTION,
            profile_competence_mention.id
          );
          toast(
            String(
              this.$getVisibleNames(
                "mesh.egressprofilecompetencemention",
                false,
                "Mención"
              ) + " eliminada."
            )
          );
        }
      });
    },
  },
  mounted() {},
  created() {
    // this.$store.dispatch(names.FETCH_PROFILE_COMPETENCES_MENTION, {
    //   egress_profile_id: this.egress_profile_id,
    // });
  },
};
</script>

<style scoped src="@/utils/rich_text_editor.css">
</style>
<style scoped>
table th {
  border: 1px solid black;
  vertical-align: middle;
  background-color: var(--primary-color) !important;
  color: var(--primary-font-color);
  font-size: var(--primary-font-size);
  padding: 2px 2px;
}
.profile-competence-mention-header {
  background: var(--primary-color) !important;
  /* border: 1px solid #000 !important; */
}
.profile-competence-button-div {
  float: right;
}
table td {
  text-align: left;
  vertical-align: middle;
  font-size: var(--secondary-font-size);
  padding: 2px 2px;
}
.grabbable {
  cursor: grab;
  cursor: -moz-grab;
  cursor: -webkit-grab;
}
.grabbable:active {
  cursor: grabbing;
  cursor: -moz-grabbing;
  cursor: -webkit-grabbing;
}
.badge-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: left;
}
.badge {
  background-color: var(--primary-color);
  color: white;
  max-width: fit-content;
  border-radius: 3px;
  margin: 0.1em 0.1em;
  padding: 1px 4px;
  flex-grow: 1;
  font-size: var(--thirth-font-size);
  text-align: center;
}
.badge-containerEPC {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin: 0.2em 0em;
  gap: 5px;
  justify-content: center;
}
.badgeEPC {
  background-color: var(--primary-color);
  color: white;
  font-weight: bold;
  max-width: fit-content;
  border-radius: 5px;
  margin: 0.1em 0.1em;
  padding: 0.8em;
  line-height: 1;
  flex-grow: 1;
  font-size: var(--thirth-font-size);
  text-align: center;
}
@media print {
  .badge {
    border: none;
  }
}
</style>